import directus from "api/services/directus";
import { FAQ } from "custom-types/Directus";
import { filterByCountry } from "utils/finder/directusRequestUtils";
import logError from "utils/logError";

export const getDirectusFAQs = async (
  collection: string,
  slug: string,
  countryCode = "us",
) => {
  const params = {
    fields: "*,location.country",
    "filter[slug][_eq]": slug,
    "filter[status][_eq]": "published",
  };
  try {
    const result = await directus.get<FAQ[]>(collection, {
      params,
    });

    const { data: directusData } = result || {};
    const data = filterByCountry(directusData, countryCode)[0];

    return { data: data || { faqs: [] } };
  } catch (e) {
    logError(e.message, {
      functionName: "getDirectusFAQs",
      service: "directus",
      statusCode: e.statusCode,
    });

    return { data: { faqs: [] } };
  }
};
