import { localizeText } from "utils/localizeText";

export const getRetailTypeLabel = (
  type: "dispensary" | "cbd-store" | "clinic",
  countryCode: string,
  isPlural?: boolean,
) => {
  switch (type) {
    case "dispensary":
      return isPlural
        ? localizeText("Dispensaries", countryCode)
        : localizeText("Dispensary", countryCode);
    case "cbd-store":
      return isPlural ? "Hemp / CBD Stores" : "Hemp / CBD Store";
    case "clinic":
      return isPlural ? "Doctors" : "Doctor";
    default:
      return "";
  }
};
