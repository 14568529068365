import { ReactElement } from "react";
import Link from "next/link";

import { useEventTracker } from "hooks/useEventTracker";

import TrackImpression from "components/TrackImpression";

type Props = {
  link: string;
  header: string;
  content?: ReactElement | string;
  className: string;
  trackingInfo: {
    category: string;
    label: string;
  };
};

export const LinkButton = ({
  link,
  header,
  content = "Find nearby",
  className,
  trackingInfo,
}: Props) => {
  const { publishEvent } = useEventTracker();

  return (
    <Link
      href={link}
      className={className}
      onClick={() => {
        publishEvent({
          action: "Click",
          category: trackingInfo.category,
          label: trackingInfo.label,
        });
      }}
      data-testid={`${header}-button-link`}
    >
      <TrackImpression
        category={trackingInfo.category}
        label={trackingInfo.label}
      >
        <button className="flex flex-row justify-between items-center p-md h-full w-full border bg-white rounded border-light-grey">
          <div className="text-xs flex flex-col text-left">
            <span className="text-green font-bold">{header}</span>
            <span>{content}</span>
          </div>
        </button>
      </TrackImpression>
    </Link>
  );
};
export default LinkButton;
