import { ParsedUrlQuery } from "querystring";

import locations from "constants/finder/locations";
import { Location } from "custom-types/Location";
import { MapData } from "custom-types/StoreLocator";

import { radiusToZoom } from "../finder/mapUtils";

export const getMapSetup = (
  query: ParsedUrlQuery,
  location: Location | undefined,
): MapData => {
  const { right_lon, top_lat, bottom_lat, left_lon } = query;

  if (top_lat && right_lon && bottom_lat && left_lon) {
    return {
      bounds: {
        east: Number(right_lon),
        north: Number(top_lat),
        south: Number(bottom_lat),
        west: Number(left_lon),
      },
    };
  }

  return setup(query, location);
};

const setup = (query: ParsedUrlQuery, finderLocation: Location | undefined) => {
  // We want to use lon instead of lng for consistency across
  // the app, but will support both here for legacy urls
  const { lat, lng, lon, zoom } = query;

  return {
    center: {
      lat: lat && (lon || lng) ? Number(lat) : finderLocation?.coordinates?.lat,
      lon:
        lat && (lon || lng)
          ? Number(lon || lng)
          : finderLocation?.coordinates?.lon,
    },
    zoom: processedZoom(finderLocation, zoom),
  };
};

const processedZoom = (
  finderLocation: Location | undefined,
  zoom: string | string[] | undefined,
) => {
  const radius = getCachedRadius(finderLocation);

  if (radius) {
    return radiusToZoom(radius);
  } else if (zoom) {
    return parseInt(`${zoom}`);
  }
  return 13;
};

const getCachedRadius = (finderLocation: Location | undefined) => {
  const normalizedLocationSlug = getNormalizedLocationSlug(finderLocation);
  return locations[normalizedLocationSlug as keyof typeof locations]?.radius;
};

const getNormalizedLocationSlug = (finderLocation: Location | undefined) => {
  const { city, geoSlug } = finderLocation || {};

  return finderLocation && !city
    ? geoSlug?.slice(0, geoSlug?.lastIndexOf("-us"))
    : geoSlug;
};
