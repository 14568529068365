import React, { createContext, useState } from "react";
import { useRouter } from "next/navigation";

import { MapMarker, Store } from "custom-types/StoreLocator";

export type StoreLocatorContextProps = {
  selectedStore?: Store;
  selectedMarker?: MapMarker;
  hoveredStore?: number;
  setSelectedStore: (store?: Store) => void;
  setSelectedMarker: (marker?: MapMarker) => void;
  setHoveredStore: (storeId?: number) => void;
  clearHoveredStore: () => void;
  handleMarkerClick: (
    store: Store,
    handleClickEvent?: () => void,
    marker?: MapMarker,
  ) => void;
};
const StoreLocatorContext = createContext<StoreLocatorContextProps>({
  clearHoveredStore: () => {},
  handleMarkerClick: () => {},
  setHoveredStore: () => {},
  setSelectedMarker: () => {},
  setSelectedStore: () => {},
});

type StoreLocatorProviderProps = {
  children: React.ReactNode;
};

export const StoreLocatorProvider = ({
  children,
}: StoreLocatorProviderProps) => {
  const [selectedStore, setSelectedStore] = useState<Store>();
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>();
  const [hoveredStore, setHoveredStore] = useState<number>();
  const clearHoveredStore = () => setHoveredStore(undefined);
  const { push } = useRouter();

  const handleMarkerClick = (
    store?: Store,
    handleClickEvent?: () => void,
    marker?: MapMarker,
  ) => {
    if (!store) {
      return;
    }

    if (selectedStore?.id !== store.id) {
      setSelectedStore(store);
      setSelectedMarker(marker);
      document
        .getElementById(`store-card-${store.id}`)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      handleClickEvent && handleClickEvent();
      push(store.path);
    }
  };
  return (
    <StoreLocatorContext.Provider
      value={{
        clearHoveredStore,
        handleMarkerClick,
        hoveredStore,
        ...(selectedStore && { selectedStore }),
        ...(selectedMarker && { selectedMarker }),
        setHoveredStore,
        setSelectedMarker,
        setSelectedStore,
      }}
    >
      {children}
    </StoreLocatorContext.Provider>
  );
};

export default StoreLocatorContext;
