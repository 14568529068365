import { useMemo } from "react";
import classnames from "classnames";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import useFilters from "hooks/useFilters";

import Dropdown from "components/botanic/Dropdown";
import DropdownItem from "components/botanic/Dropdown/DropdownItem";

const SortDropdown = ({
  availableSorts,
  eventCategory,
  sortDisplayStrings,
  onToggleDropdown,
}: {
  availableSorts: string[];
  eventCategory: Category;
  sortDisplayStrings: Record<string, string>;
  onToggleDropdown?: () => void;
}) => {
  const { publishEvent } = useEventTracker();
  const { selectFilterValue, addFilter } = useFilters({
    shallowRouting: false,
  });

  const validAvailableSorts = useMemo(
    () =>
      availableSorts.filter((value) =>
        Object.keys(sortDisplayStrings).includes(value),
      ),
    [availableSorts, sortDisplayStrings],
  );

  if (validAvailableSorts.length === 0) {
    return <></>;
  }

  const sortValue = selectFilterValue("sort") || validAvailableSorts[0];

  const handleClick = (value: string) => {
    addFilter({ sort: value });

    publishEvent({
      action: Action.click,
      category: eventCategory,
      label: sortDisplayStrings[value].toLowerCase(),
    });
  };

  return (
    <div
      data-testid="sort-dropdown"
      className="flex flex-shrink-0 items-center justify-center text-xs ml-lg"
    >
      Sort by&nbsp;
      <Dropdown
        className="items-center"
        selectedItem={sortDisplayStrings[sortValue as string]}
        onToggleDropdown={onToggleDropdown}
      >
        <>
          {(validAvailableSorts || []).map((value) => (
            <DropdownItem
              key={`sort-${value}`}
              className={classnames("p-md z-10 whitespace-nowrap", {
                "font-extrabold": sortValue === value,
              })}
              data-testid={`listing-sort-${value}`}
              onClick={() => handleClick(value)}
            >
              {`${sortDisplayStrings[value]}`}
            </DropdownItem>
          ))}
        </>
      </Dropdown>
    </div>
  );
};

export default SortDropdown;
