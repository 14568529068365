import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

import getDispensariesV3 from "api/requests/consumerApi/getDispensariesV3";
import { DispensaryV3 } from "custom-types/DispensariesV3";
import { RetailType } from "custom-types/Store";
import { getCoordinates } from "redux/selectors/location";
import { getUserHistoryItems } from "utils/userHistory";

const RecentlyViewedCarousel = dynamic(
  () => import("./RecentlyViewedCarousel"),
  { ssr: false },
);

const RecentlyViewedCarouselWithData: React.FC<{ retailType: RetailType }> = ({
  retailType,
}) => {
  const coordinates = useSelector(getCoordinates);
  const [recentlyViewedStores, setRecentlyViewedStores] = useState<
    DispensaryV3[]
  >([]);
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const getRecentlyViewed = async () => {
    const storedDispensaries = await getUserHistoryItems("dispensary", {}, 5);
    const recentlyViewedIds = storedDispensaries?.length
      ? storedDispensaries.map((entry) => entry.resourceId).filter((id) => id)
      : [];
    if (recentlyViewedIds.length) {
      const { stores } = await getDispensariesV3({
        disableGeoFilter: true, // don't filter by geo, only use for calc distances
        flags: [retailType],
        ids: recentlyViewedIds,
        lat: coordinates?.lat,
        lon: coordinates?.lon,
        merchUnitName: "NONE", // don't split out platinums
      });

      if (stores.length) {
        setRecentlyViewedStores(
          stores.sort(
            (a, b) =>
              recentlyViewedIds.indexOf(a.id) - recentlyViewedIds.indexOf(b.id),
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (inView) {
      getRecentlyViewed();
    }
  }, [coordinates, inView]);

  return (
    <div ref={ref} className="ml-lg mt-xl">
      {recentlyViewedStores.length > 0 && (
        <RecentlyViewedCarousel stores={recentlyViewedStores} />
      )}
    </div>
  );
};

export default RecentlyViewedCarouselWithData;
