import { FAQ, FaqItem } from "custom-types/Directus";
import { Address, Store } from "custom-types/StoreLocator";

export default (stores: Store[], faq: FAQ | null, domain: string) => {
  return {
    "@context": "https://schema.org",
    "@type": "SearchResultsPage",
    mainEntity: {
      "@type": "ItemList",
      itemListElement: stores.map((store) =>
        generateStoreSchema(store, domain),
      ),
    },
    ...(faq?.faqs && { hasPart: generateFaqsSchema(faq) }),
  };
};

const generateStoreSchema = (store: Store, domain: string) => {
  return {
    "@type": "Store",
    address: generateAddressSchemas(store.address),
    name: store.name,
    url: `${domain}/${store.path}`,
    ...(store.imageUrl && { image: store.imageUrl }),
    ...(store.logoUrl && { logo: store.logoUrl }),
    ...(store.address?.lat && { latitude: store.address.lat }),
    ...(store.address?.lon && { longitude: store.address.lon }),
    ...(store.reviewCount > 0 && {
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: store.reviewRating,
        reviewCount: store.reviewCount,
      },
    }),
  };
};

const generateAddressSchemas = (address: Address) => {
  return {
    "@type": "PostalAddress",
    addressCountry: address?.country,
    postalCode: address?.zip,
    streetAddress: address?.address1,
    ...(address?.city && { addressLocality: address.city }),
    ...(address?.state && { addressRegion: address.state }),
  };
};

const generateFaqsSchema = (faq: FAQ) => {
  return {
    "@type": "FAQPage",
    mainEntity: faq?.faqs?.map((faqItem) => generateFaqSchema(faqItem)),
  };
};

const generateFaqSchema = (faqItem: FaqItem) => {
  return {
    "@type": "Question",
    acceptedAnswer: {
      "@type": "Answer",
      text: faqItem?.answer,
    },
    name: faqItem?.question,
  };
};
