import { useState } from "react";

import { MapMarkerTierEnum } from "custom-types/StoreLocator";

type Props = {
  logoImage: string;
  alt: string;
  tierName: MapMarkerTierEnum;
};

const MapMarkerImage = ({ logoImage, alt, tierName }: Props) => {
  const [useBackupImage, setUseBackupImage] = useState(false);

  if (
    !useBackupImage &&
    [MapMarkerTierEnum.Sponsored, MapMarkerTierEnum.Pro].includes(tierName)
  ) {
    const imgSize = tierName === MapMarkerTierEnum.Sponsored ? 60 : 44;
    const optimizedSrc = `${logoImage}?auto=compress,format&w=${imgSize}`;
    return (
      <img
        alt={alt}
        srcSet={`${DPR_SUPPORT.map((dpr) =>
          createDprSrc(optimizedSrc, dpr),
        ).join(",")}`}
        src={optimizedSrc}
        className="map-marker__image w-full absolute rounded-full bg-white lazyload"
        onError={() => {
          setUseBackupImage(true);
        }}
      />
    );
  }

  return null;
};

const DPR_SUPPORT = [1, 2, 3];

const createDprSrc = (src: string, dpr: number) => `${src}&dpr=${dpr} ${dpr}x`;

export default MapMarkerImage;
