import { ParsedUrlQuery, stringify } from "querystring";

const LEGACY_FILTER_MAP: Record<string, string> = {
  black_owned: "ownership",
  medical: "dispensary-type",
  open_now: "store-hours",
};

/**
 * Replaces legacy finder filter parameters in the query object.
 * @param query - The ParsedUrlQuery object from Next.js
 * @returns A new query string if old filters exist, otherwise null
 */
const replaceLegacyFinderFilters = (query: ParsedUrlQuery): string | null => {
  if (!query?.filter) {
    return null;
  }

  const filters = Array.isArray(query.filter) ? query.filter : [query.filter];

  const updatedQuery: Record<string, string[]> = {};

  filters.forEach((curr) => {
    if (LEGACY_FILTER_MAP[curr]) {
      const newKey = `filter[${LEGACY_FILTER_MAP[curr]}]`;
      const newValue = curr.replace(/_/g, "-"); // New format uses hypens, not underscores
      updatedQuery[newKey] = [...(updatedQuery[newKey] || []), newValue];
    }
  });

  return stringify(updatedQuery);
};

export default replaceLegacyFinderFilters;
