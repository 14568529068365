import React from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

const SearchButton: React.FC<{
  isLoading?: boolean;
  clearSeachButton: () => void;
}> = ({ isLoading, clearSeachButton }) => {
  const map = useMap();
  const { push } = useRouter();
  const pathname = usePathname();
  const params = useSearchParams();
  const handleClick = () => {
    const ne = map?.getBounds()?.getNorthEast();
    const sw = map?.getBounds()?.getSouthWest();
    if (!isLoading && ne && sw) {
      const newParams = new URLSearchParams(params?.toString());
      // we want to default to first page here
      newParams.delete("page");

      newParams.set("top_lat", String(ne.lat()));
      newParams.set("right_lon", String(ne.lng()));
      newParams.set("bottom_lat", String(sw.lat()));
      newParams.set("left_lon", String(sw.lng()));

      push(`${pathname}?${newParams.toString()}`);
      clearSeachButton();
    }
  };

  return (
    <button
      className="search-button rounded-full h-9 leading-9 shadow-low bg-default text-white text-xs px-xl mt-lg pointer-events-auto"
      id={isLoading ? "search-loading" : "search-this-area"}
      onClick={handleClick}
    >
      {isLoading ? "Loading markers" : "Search this area"}
    </button>
  );
};

export default SearchButton;
