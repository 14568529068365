import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useMap } from "@vis.gl/react-google-maps";

const ZoomControls: React.FC<{
  setShowSearchButton: (showSearchButton: boolean) => void;
}> = ({ setShowSearchButton }) => {
  const map = useMap();
  const handleZoomIn = () => {
    trackEvent("Map", "Zoom In");
    const newZoom = Math.min((map?.getZoom() || 0) + 1, 20);
    map?.setZoom(newZoom);
    setShowSearchButton(true);
  };

  const handleZoomOut = () => {
    trackEvent("Map", "Zoom Out");
    const newZoom = Math.max((map?.getZoom() || 0) - 1, 1);
    map?.setZoom(newZoom);
    setShowSearchButton(true);
  };
  const classes =
    "h-10 w-10 leading-none border border-light-grey text-green text-xxl";
  return (
    <div className="bg-white flex flex-col font-bold text-lg lg:flex my-xl shadow-low pointer-events-auto">
      <button
        data-testid="store-locator-zoom-in"
        onClick={handleZoomIn}
        className={classes}
      >
        &#43;
      </button>
      <button
        data-testid="store-locator-zoom-out"
        onClick={handleZoomOut}
        className={classes}
      >
        &minus;
      </button>
    </div>
  );
};

export default ZoomControls;
