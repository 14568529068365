import { Label } from "constants/events";

export const getSponsoredCardEventLabel = (slot: number) => {
  return `platinum placement - list ${slot + 1}`;
};

export const getOrganicCardEventLabel = (featureTier: string) => {
  let label;
  switch (featureTier) {
    case "pro":
      label = Label.finderProStoreCard;
      break;
    case "info":
      label = Label.finderInfoStoreCard;
      break;
    default:
      label = "";
      break;
  }

  return label;
};
