import React, { useCallback, useState } from "react";
import classnames from "classnames";
import kebabCase from "lodash/kebabCase";
import { useRouter } from "next/navigation";

import { DISPENSARY } from "constants/storeLocatorConfig";
import { RetailType } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { convertCoordinatesToLocation } from "utils/convertGoogleGeocoderToLocation";
import getStoreLocatorRoute from "utils/storeLocator/getStoreLocatorRoute";

import LocationSearchIcon from "components/Icons/location_search.svg";

const Geolocator = ({
  retailType = DISPENSARY,
}: {
  retailType: RetailType;
}) => {
  const [working, setWorking] = useState(false);

  const router = useRouter();
  const countryCode = useDomainCountryCode();

  const storeLocatorRoute = getStoreLocatorRoute(retailType, countryCode);

  const geolocatorClasses = classnames(
    "geolocator-button",
    "h-10 w-10 text-green flex items-center justify-center bg-white rounded-full shadow-low pointer-events-auto",
  );

  const onGeolocationSuccess = useCallback(
    async (position: GeolocationPosition) => {
      try {
        const formattedLocation = await convertCoordinatesToLocation(
          position.coords,
        );

        let url = kebabCase(formattedLocation.state);
        if (formattedLocation.city) {
          url += "/" + kebabCase(formattedLocation.city);
        }
        router.push(`${storeLocatorRoute}/${url}`);
      } catch (e) {
        alert("Unable to set your current location. Please try again.");
      }
    },
    [],
  );

  // For explanation on error codes visit
  // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError#instance_properties
  const onGeolocationError = useCallback((error: GeolocationPositionError) => {
    let message = "";

    if (error.code === 1) {
      message =
        "You need to enable geolocation services in your browser to use this feature.";
    } else {
      message = "Unable to determine location. Please try again.";
    }

    alert(message);
  }, []);

  const onClick = () => {
    if (navigator?.geolocation) {
      setWorking(true);
      navigator.geolocation.getCurrentPosition(
        onGeolocationSuccess,
        onGeolocationError,
      );
    } else {
      alert("Your browser does not support this feature.");
    }
    setWorking(false);
  };

  return (
    <button
      title="click to use your location"
      className={geolocatorClasses}
      onClick={onClick}
    >
      <LocationSearchIcon
        className={working ? "animate-pulse" : ""}
        width="30"
        height="30"
      />
    </button>
  );
};

export default Geolocator;
