import consumerApi from "api/services/consumer-api";
import publicConfig from "config/public";
import { Query } from "custom-types/Query";
import { RetailType } from "custom-types/RetailTypes";
import { MapData, Store, StoreSeo } from "custom-types/StoreLocator";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

import { AvailableFilter } from "components/UnifiedShop/DynamicFilters/types";

import { StoreLocatorLocation } from "../../../utils/storeLocator/getLocation";
export type StoreLocatorResultsResponse = {
  data: StoreResults;
  metadata: Metadata;
};

type StoreResults = {
  organicStores: Store[];
  sponsoredStores: Store[];
  spotlightStores: Store[];
};

type Metadata = {
  seo: StoreSeo;
  availableSorts: string[];
  // TODO update this API to use consumer-api-fetch NPM package, and this typing will come for free
  availableFilters: AvailableFilter[];
  supportedFilters: string[];
  totalCount: number;
};

const mapStoresImages = (stores: Store[]) =>
  stores.map((store) => ({
    ...store,
    imageUrl: store.imageUrl ? formatImgixUrl(store.imageUrl) : null,
    logoUrl: store.logoUrl ? formatImgixUrl(store.logoUrl) : null,
    mapMarkers: store.mapMarkers.map((marker) => ({
      ...marker,
      imageUrl: marker.imageUrl
        ? formatImgixUrl(marker.imageUrl)
        : marker.imageUrl,
    })),
  }));

export const STORE_LOCATOR_RESULTS_TAKE = 30;

type StoreLocatorRequestParams = Partial<{
  take: number;
  skip: number;
  lat: number;
  lon: number;
  retailType: RetailType;
  top_lat: number;
  left_lon: number;
  bottom_lat: number;
  right_lon: number;
  city: string;
  radius: string;
  region: string;
  organization_ids: number[];
  sort: string;
}>;

const { timeout } = publicConfig;

const getStoreLocatorResults = async ({
  filter,
  location,
  mapData,
  page = 1,
  retailType,
  sort,
  organization_id,
}: {
  filter: Query | undefined;
  location: StoreLocatorLocation;
  mapData: MapData;
  page?: number;
  sort?: string;
  retailType: RetailType;
  organization_id: string | string[] | undefined;
}): Promise<StoreLocatorResultsResponse> => {
  try {
    const params: StoreLocatorRequestParams = {
      take: STORE_LOCATOR_RESULTS_TAKE,
    };
    if (mapData.bounds) {
      params.top_lat = mapData.bounds.north;
      params.left_lon = mapData.bounds.west;
      params.bottom_lat = mapData.bounds.south;
      params.right_lon = mapData.bounds.east;
    } else if (mapData.center) {
      params.lat = mapData.center.lat;
      params.lon = mapData.center.lon;
    }

    if (retailType) {
      params.retailType = retailType;
    }

    if (page > 1) {
      params.skip = (page - 1) * STORE_LOCATOR_RESULTS_TAKE;
    }

    if (
      organization_id &&
      !Array.isArray(organization_id) &&
      !isNaN(parseInt(organization_id))
    ) {
      params.organization_ids = [parseInt(organization_id)];
    }

    if (location.isLocationPage) {
      params.city = location.finderLocation?.city;
      params.region = location.finderLocation?.state;
      if (location.finderLocation?.radius) {
        params.radius = `${location.finderLocation.radius}mi`;
      }
    }

    if (sort) {
      params.sort = sort;
    }

    const mapFilterObjectToAPI = (filters: Query) =>
      Object.keys(filters).reduce((filterObj, filterKey) => {
        return {
          ...filterObj,
          [`filter[${filterKey}][]`]: filters[filterKey],
        };
      }, {});

    const { data, metadata }: { data: StoreResults; metadata: Metadata } =
      await consumerApi
        .get("/api/stores/v1/", {
          params: {
            ...params,
            ...(filter && { ...mapFilterObjectToAPI(filter) }),
          },
          timeout: timeout.storeSearch,
        })
        .then(({ data }) => data);

    const dataWithOptimizedImages = {
      organicStores: mapStoresImages(data.organicStores),
      sponsoredStores: mapStoresImages(data.sponsoredStores),
      spotlightStores: mapStoresImages(data.spotlightStores),
    };

    return {
      data: dataWithOptimizedImages,
      metadata: metadata,
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getStoreLocatorResults",
      service: "consumer",
      statusCode: e.statusCode,
    });
    return {
      data: {
        organicStores: [],
        sponsoredStores: [],
        spotlightStores: [],
      },
      metadata: {
        availableFilters: [],
        availableSorts: [],
        seo: {
          canonicalUrl: null,
          description: null,
          headline: null,
          indexEnabled: false,
          pageTitle: null,
        },
        supportedFilters: [],
        totalCount: 0,
      },
    };
  }
};

export default getStoreLocatorResults;
