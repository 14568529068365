import { Coordinates } from "custom-types/Coordinates";
import { MapData, Store } from "custom-types/StoreLocator";
import initializeGoogleMapsLatLng from "utils/maps/initializeGoogleMapsLatLng";
import initializeGoogleMapsLatLngBounds from "utils/maps/initializeGoogleMapsLatLngBounds";

export type Bounds = {
  nw: Coordinates;
  ne: Coordinates;
  sw: Coordinates;
  se: Coordinates;
};

export type BoundsNWSECorners = Pick<Bounds, "nw" | "se">;

export type GetFittedBoundsParams = {
  mapData: MapData;
  stores: Store[];
};

export type FittedBounds = {
  center: {
    lat: number;
    lon: number;
  };
  zoom: number;
  newBounds: Bounds;
};

export const getFittedBounds = async ({
  mapData,
  stores,
}: GetFittedBoundsParams) => {
  let bounds: google.maps.LatLngBounds | undefined;

  if (mapData?.bounds) {
    const northWest = await initializeGoogleMapsLatLng(
      mapData.bounds.north,
      mapData.bounds.west,
    );
    const southEast = await initializeGoogleMapsLatLng(
      mapData.bounds.south,
      mapData.bounds.east,
    );
    bounds = await initializeGoogleMapsLatLngBounds(northWest, southEast);
  } else if (mapData?.center) {
    bounds = await initializeGoogleMapsLatLngBounds();
    const centerLatLng = await initializeGoogleMapsLatLng(
      mapData.center.lat,
      mapData.center.lon,
    );
    if (centerLatLng) {
      bounds?.extend(centerLatLng);
    }

    for (const store of stores) {
      for (const marker of store.mapMarkers) {
        const point = await initializeGoogleMapsLatLng(marker.lat, marker.lon);
        if (point) {
          bounds?.extend(point);
        }
      }
    }
  }

  const neLng = bounds?.getNorthEast().lng();
  const neLat = bounds?.getNorthEast().lat();
  const swLat = bounds?.getSouthWest().lat();
  const swLng = bounds?.getSouthWest().lng();

  if (swLng === neLng || neLat === swLat) {
    return;
  }

  return bounds;
};
