import React from "react";

import StorefrontIcon from "components/Icons/storefront.svg";

type Props = {
  pickupReadiness: boolean;
};

const PickupLabel: React.FC<Props> = ({ pickupReadiness }) => {
  const label = pickupReadiness ? "Pickup in under 30 mins" : "Pickup";

  return (
    <div className="flex items-center bg-leafly-white rounded px-sm text-xs min-h-6">
      <div className="flex items-center justify-center">
        <StorefrontIcon width="12" height="12" aria-hidden="true" />
        <span className="ml-xs font-bold my-0.5">{label}</span>
      </div>
    </div>
  );
};

export default PickupLabel;
