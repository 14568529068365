import React from "react";
import { jsonLdScriptProps } from "react-schemaorg";
import { SearchResultsPage, WithContext } from "schema-dts";

import { FAQ } from "custom-types/Directus";
import { Store, StoreSeo } from "custom-types/StoreLocator";
import useDomain from "hooks/useDomain";
import generateStoreLocatorSchema from "utils/schemas/generateStoreLocatorSchema";

import MetaTags from "components/MetaTags";

const DispensariesHead = ({
  seo,
  seoOverride,
  stores,
}: {
  seo: StoreSeo;
  seoOverride: FAQ | null;
  stores: Store[];
}) => {
  const domain = useDomain();

  const description =
    seoOverride?.meta_description ||
    seo.description ||
    "Find the best recreational and medical marijuana dispensaries near you. Get ratings, read customer reviews, browse menus, get directions. Shop THC edibles, flower, carts, pre-rolls, dabs, and more.";
  const title =
    seoOverride?.meta_title ||
    seo.pageTitle ||
    "Find a Recreational or Medical Dispensary Near You with Leafly";
  const robots = seo.indexEnabled ? "index" : "noindex";

  // Look for Directus override first, then consumer-api
  const canonical = seoOverride?.canonical || seo.canonicalUrl || undefined;

  return (
    <>
      <MetaTags
        canonicalOverride={canonical}
        description={description}
        robots={robots}
        shouldShowAlternates={false}
        title={title}
      >
        <script
          {...jsonLdScriptProps<SearchResultsPage>(
            generateStoreLocatorSchema(
              stores,
              seoOverride,
              domain,
            ) as WithContext<SearchResultsPage>,
          )}
        />
      </MetaTags>
    </>
  );
};

export default DispensariesHead;
