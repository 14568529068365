import React from "react";

import { MapMarkerTierEnum } from "custom-types/StoreLocator";

function MarkerShadow() {
  return <use href="#marker-shadow" />;
}

type Props = {
  isHovered?: boolean;
  tier: MapMarkerTierEnum;
};

type SubcomponentProps = {
  isHovered?: boolean;
};

function SponsoredMarker({ isHovered }: SubcomponentProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 87 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <MarkerShadow />
      <path
        d="M84 42.5c0 6.366-2.562 13.245-6.505 20.041-3.937 6.783-9.2 13.397-14.485 19.213-5.283 5.813-10.569 10.806-14.535 14.348a186.682 186.682 0 01-4.789 4.137c-.489.408-.888.735-1.186.977a112.76 112.76 0 01-1.186-.977 186.682 186.682 0 01-4.789-4.137c-3.966-3.541-9.252-8.535-14.535-14.348-5.286-5.816-10.548-12.43-14.484-19.213C3.562 55.745 1 48.866 1 42.5 1 19.58 19.58 1 42.5 1S84 19.58 84 42.5z"
        fill="#FFCD00"
        stroke="white"
        strokeWidth="2"
        filter={isHovered ? "url(#marker-shadow)" : ""}
      />
    </svg>
  );
}

function ProMarker({ isHovered }: SubcomponentProps) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32">
      <MarkerShadow />
      <circle
        cx="16"
        cy="16"
        r="16"
        fill="#017c6b"
        filter={isHovered ? "url(#marker-shadow)" : ""}
      />
    </svg>
  );
}

function PaidMarker({ isHovered }: SubcomponentProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 87 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <MarkerShadow />
      <path
        d="M84 42.5c0 6.366-2.562 13.245-6.505 20.041-3.937 6.783-9.2 13.397-14.485 19.213-5.283 5.813-10.569 10.806-14.535 14.348a186.682 186.682 0 01-4.789 4.137c-.489.408-.888.735-1.186.977a112.76 112.76 0 01-1.186-.977 186.682 186.682 0 01-4.789-4.137c-3.966-3.541-9.252-8.535-14.535-14.348-5.286-5.816-10.548-12.43-14.484-19.213C3.562 55.745 1 48.866 1 42.5 1 19.58 19.58 1 42.5 1S84 19.58 84 42.5z"
        fill="#017c6b"
        filter={isHovered ? "url(#marker-shadow)" : ""}
      />
    </svg>
  );
}

function InfoMarker({ isHovered }: SubcomponentProps) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 32 32">
      <MarkerShadow />
      <circle
        cx="16"
        cy="16"
        r="16"
        fill="#81a39c"
        filter={isHovered ? "url(#marker-shadow)" : ""}
      />
    </svg>
  );
}

export default function DynamicMarker({ tier, isHovered }: Props) {
  switch (tier) {
    case MapMarkerTierEnum.Sponsored:
      return <SponsoredMarker isHovered={isHovered} />;
    case MapMarkerTierEnum.Pro:
      return <ProMarker isHovered={isHovered} />;
    case MapMarkerTierEnum.Paid:
      return <PaidMarker isHovered={isHovered} />;
    default:
      return <InfoMarker isHovered={isHovered} />;
  }
}
