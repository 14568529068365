import React from "react";

import Button from "components/botanic/Button";

type Props = {
  deliveryEnabled: boolean;
  handleClick: () => void;
  inStoreCartEnabled: boolean;
  pickupEnabled: boolean;
  storePath: string;
};

const ctaText = (
  deliveryEnabled: boolean,
  inStoreCartEnabled: boolean,
  pickupEnabled: boolean,
) => {
  if (inStoreCartEnabled || (pickupEnabled && !deliveryEnabled)) {
    return "order pickup";
  }
  if (!pickupEnabled && deliveryEnabled) {
    return "order delivery";
  }
  if (pickupEnabled && deliveryEnabled) {
    return "order delivery or pickup";
  }
};

const CTAButton: React.FC<Props> = ({
  deliveryEnabled,
  handleClick,
  inStoreCartEnabled,
  pickupEnabled,
  storePath,
}) => {
  const ctaDisplay = ctaText(
    deliveryEnabled,
    inStoreCartEnabled,
    pickupEnabled,
  );
  if (!ctaDisplay) {
    return <></>;
  }

  return (
    <div className="w-full mt-lg">
      <Button
        width="full"
        href={`${storePath}/menu`}
        onClick={handleClick}
        data-testid="dispensary-card__cta"
      >
        {ctaDisplay}
      </Button>
    </div>
  );
};

export default CTAButton;
