import React, { useContext } from "react";
import classnames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";

import { Action, Category, Label } from "constants/events";
import StoreLocatorContext from "context/StoreLocatorContext";
import { Store } from "custom-types/StoreLocator";
import { SVGProps } from "custom-types/SVGProps";
import { useEventTracker } from "hooks/useEventTracker";
import {
  getOrganicCardEventLabel,
  getSponsoredCardEventLabel,
} from "utils/storeLocator/eventTracking";

import CardRibbon from "components/botanic/DispensaryCard/CardRibbon";
import ScheduleStatus from "components/botanic/ScheduleStatus";
import StarRating from "components/botanic/StarRating";
import AwardBadgeIcon from "components/Icons/award_badge.svg";
import Image from "components/Image";
import OfferLabel from "components/OfferLabel";
import TrackImpression from "components/TrackImpression";

import CTAButton from "./CTAButton";
import PickupLabel from "./PickupLabel";

const ScooterIcon = dynamic<SVGProps>(
  () => import("components/Icons/scooter.svg"),
);

const StorefrontIcon = dynamic<SVGProps>(
  () => import("components/Icons/storefront.svg"),
);

const StoreCard: React.FC<{
  isSponsored: boolean;
  slot: number;
  store: Store;
}> = ({ isSponsored, slot, store }) => {
  const { publishEvent } = useEventTracker();
  const clickLabel = isSponsored
    ? getSponsoredCardEventLabel(slot)
    : getOrganicCardEventLabel(store.featureTier);

  const impressionLabel = store.configurations.newFlagEnabled
    ? Label.finderNewStoreCard
    : clickLabel;

  const handleCardClick = () => {
    publishEvent({
      action: Action.click,
      category: Category.finder,
      dealId: store.dealId || undefined,
      dispensaryId: store.id,
      label: clickLabel,
      merchandisingCampaignId: store.merchandisingCampaignId || undefined,
    });
  };

  const { setHoveredStore, clearHoveredStore, selectedStore } =
    useContext(StoreLocatorContext);

  const logoSize =
    store.configurations.imageEnabled && !store.imageUrl ? 128 : 48;
  const showImage = store.configurations.imageEnabled && store.imageUrl;

  return (
    <li
      id={`store-card-${store.id}`}
      className="h-full"
      onMouseEnter={() => setHoveredStore(store.id)}
      onMouseLeave={clearHoveredStore}
    >
      <TrackImpression
        category={Category.finder}
        label={impressionLabel}
        customDimensions={{
          dealId: store.dealId || undefined,
          merchandisingCampaignId: store.merchandisingCampaignId || undefined,
        }}
      >
        <div
          className={classnames(
            "flex flex-col items-start justify-between relative h-full w-full",
            "rounded overflow-hidden bg-white p-lg border border-light-grey my-sm",
            {
              "shadow-low": store.id === selectedStore?.id,
            },
          )}
        >
          <Link
            href={store.path}
            className="w-full"
            onClick={handleCardClick}
            data-testid="store-card-link"
          >
            <div
              className={classnames("relative w-full", { flex: !showImage })}
            >
              {showImage && (
                <>
                  <Image
                    src={store.imageUrl as string}
                    sizes={[312]}
                    alt={`Cover photo for ${store.name}`}
                    className="h-32 rounded mb-sm"
                    imageClass="object-cover"
                    data-testid="dispensary-card__photo"
                  />
                </>
              )}
              {store.configurations.logoEnabled && (
                <div
                  className={classnames(
                    "rounded overflow-hidden bg-white mb-sm",
                    { "absolute bottom-0 left-0 ml-sm md:block": showImage },
                  )}
                  style={{ minWidth: logoSize, width: logoSize }}
                >
                  {store.logoUrl ? (
                    <Image
                      src={store.logoUrl}
                      sizes={[logoSize]}
                      alt={`${store.name} logo`}
                      ratio="square"
                    />
                  ) : (
                    <div className="p-xs">
                      <StorefrontIcon
                        width={`${logoSize - 8}`}
                        height={`${logoSize - 8}`}
                        className="text-grey"
                        aria-label={`Default logo for ${store.name}`}
                      />
                    </div>
                  )}
                </div>
              )}
              <div
                className={classnames(
                  "ml-auto top-0 right-0 text-xs font-bold bg-white border border-light-grey rounded px-xs z-[1]",
                  { "m-sm": showImage },
                  { absolute: showImage || store.configurations.logoEnabled },
                )}
              >
                {store.mainTag}
              </div>
            </div>

            <div className="flex justify-between">
              <span className="flex truncate-lines truncate-2-lines w-55">
                {store.configurations.leaflyListEnabled && (
                  <AwardBadgeIcon
                    width={24}
                    height={24}
                    className="flex-shrink-0 inline pr-1"
                  />
                )}
                <span className=" font-bold">{store.name}</span>
              </span>
              {store.configurations.adEnabled && (
                <div>
                  <span
                    className={classnames(
                      "text-xs bg-leafly-white rounded p-1 mt-1 self-end text-grey",
                      { "ml-sm": showImage },
                    )}
                  >
                    ad
                  </span>
                </div>
              )}
            </div>

            <div>
              {store.reviewCount > 0 && (
                <StarRating
                  rating={store.reviewRating}
                  ratingCount={store.reviewCount}
                  starWidth={13}
                />
              )}
              {(store.configurations.deliveryEnabled ||
                store.configurations.pickupEnabled) && (
                <div className="flex flex-wrap my-xs">
                  {store.configurations.deliveryEnabled && (
                    <div className="flex items-center bg-leafly-white rounded px-sm mr-sm">
                      <ScooterIcon width="12" height="12" aria-hidden="true" />
                      <span className="ml-xs text-xs font-bold my-0.5">
                        Delivery
                      </span>
                    </div>
                  )}
                  {store.configurations.pickupEnabled && (
                    <PickupLabel
                      pickupReadiness={
                        store.configurations.pickupReadinessEnabled
                      }
                    />
                  )}
                </div>
              )}
              <div className="flex flex-row items-center my-xs text-xs">
                <span className="overflow-hidden">
                  {store.openStatus && (
                    <ScheduleStatus
                      currentStatus={store.openStatus}
                      className="min-h-5 flex-shrink-0 font-normal"
                      preorderConfigured={store.configurations.preorderEnabled}
                      scheduleType={"store"}
                      timeZone={store.timeZone}
                    />
                  )}
                </span>
                {store.openStatus && store.fulfillmentDetails && (
                  <span className="mx-sm flex-shrink-0 leading-none after:content-['•']"></span>
                )}
                {store.fulfillmentDetails && (
                  <span className="flex-shrink-0">
                    {store.fulfillmentDetails}
                  </span>
                )}
              </div>
            </div>
            {store.configurations.dealEnabled && (
              <div className="border-t border-light-grey text-xs pt-sm">
                {store.configurations.offerEnabled ? (
                  <OfferLabel
                    label={store.dealTitle}
                    textClassName="text-default"
                  />
                ) : (
                  <div className="flex items-start">
                    <div className="bg-notification rounded text-white font-bold mr-sm px-1 flex-shrink-0">
                      {store.dealLabel}
                    </div>
                    <span className="font-bold truncate-lines truncate-2-lines">
                      {store.dealTitle}
                    </span>
                  </div>
                )}
              </div>
            )}
          </Link>

          {store.configurations.newFlagEnabled && <CardRibbon label="new" />}

          {store.configurations.ctaEnabled && (
            <CTAButton
              deliveryEnabled={store.configurations.deliveryEnabled}
              handleClick={handleCardClick}
              inStoreCartEnabled={store.configurations.inStoreCartEnabled}
              pickupEnabled={store.configurations.pickupEnabled}
              storePath={store.path}
            />
          )}
        </div>
      </TrackImpression>
    </li>
  );
};

export default StoreCard;
