import { DispensaryTypes } from "custom-types/Finder";
import { RetailType } from "custom-types/Store";

const getRetailTypeFromUrl = (pathname: string): RetailType => {
  const finderRoutes = Object.keys(DispensaryTypes);
  let retailType: RetailType = DispensaryTypes.dispensaries;
  finderRoutes.forEach((route) => {
    if (pathname.includes(route)) {
      retailType = DispensaryTypes[route as keyof typeof DispensaryTypes];
      return;
    }
  });

  return retailType;
};

export default getRetailTypeFromUrl;
