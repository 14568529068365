import { memo } from "react";
import Link from "next/link";

import { City } from "api/requests/getCitiesNearby";
import { RetailType } from "custom-types/Store";

import TrackImpression from "components/TrackImpression";

import CityCard from "./CityCard";

export type Filter = "all" | "medical" | "recreational";

type Props = {
  heading?: string;
  cities: City[];
  retailType: RetailType;
};

const CitiesNearby = ({ heading, cities, retailType }: Props) => {
  return (
    <div data-testid="cities-nearby-wrapper" role="complementary">
      {cities.length > 0 && (
        <div data-testid="cities-nearby">
          {heading && (
            <TrackImpression category="Cities Near You" label="Cities Near You">
              <h2 className="mb-md" data-testid="cities-nearby-heading">
                {heading}
              </h2>
            </TrackImpression>
          )}
          <div className="grid grid-cols-2 gap-sm ">
            {cities.map((city, idx) => (
              <CityCard
                data={city}
                key={idx}
                extraClasses="shadow-low"
                retailType={retailType}
              />
            ))}
          </div>
          <div className="pt-xs">
            <Link
              className="text-xs font-bold underline text-green pl-px"
              href="/dispensaries/locations"
            >
              Find all locations
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(CitiesNearby);
