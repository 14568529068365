import { RetailType } from "custom-types/Store";
import { localizeText } from "utils/localizeText";

export const DISPENSARY = "dispensary";
export const CLINIC = "clinic";
export const CBD_STORE = "cbd-store";
export const storeLocatorInfo = [
  {
    countryCode: "CA",
    storeLocatorRoute: "/cbd-stores",
    storeRoute: "/cbd-store/",
    title: "CBD",
    type: CBD_STORE,
  },
  {
    countryCode: "US",
    storeLocatorRoute: "/cbd-stores",
    storeRoute: "/cbd-store/",
    title: "CBD",
    type: CBD_STORE,
  },
  {
    countryCode: "CA",
    storeLocatorRoute: "/medical-marijuana-doctors",
    storeRoute: "/doctors/",
    title: "Doctors",
    type: CLINIC,
  },
  {
    countryCode: "US",
    storeLocatorRoute: "/medical-marijuana-doctors",
    storeRoute: "/doctors/",
    title: "Doctors",
    type: CLINIC,
  },
  {
    countryCode: "CA",
    storeLocatorRoute: "/dispensaries",
    storeRoute: "/cannabis-store/",
    title: "Stores",
    type: DISPENSARY,
  },
  {
    countryCode: "US",
    storeLocatorRoute: "/dispensaries",
    storeRoute: "/dispensary-info/",
    title: "Dispensaries",
    type: DISPENSARY,
  },
];

const typeTitleMap: Record<
  RetailType,
  (count: number, countryCode: string) => string
> = {
  "cbd-store": (count) => (count > 1 ? "CBD Stores" : "CBD Store"),
  clinic: (count) => (count > 1 ? "MMJ Doctors" : "MMJ Doctor"),
  dispensary: (count, countryCode) =>
    localizeText(count > 1 ? "dispensaries" : "dispensary", countryCode),
};

export const getTypeTitle = (
  retailType: RetailType,
  count: number,
  countryCode: string,
): string => typeTitleMap[retailType](count, countryCode);
