import { DISPENSARY, storeLocatorInfo } from "constants/storeLocatorConfig";
import { RetailType } from "custom-types/Store";

export const getStoreLocatorRoute = (
  retailType: RetailType = DISPENSARY,
  countryCode = "US",
) => {
  return (
    storeLocatorInfo.find(
      (config) =>
        config.type === retailType && config.countryCode === countryCode,
    )?.storeLocatorRoute || "/"
  );
};

export default getStoreLocatorRoute;
