import React, { useEffect, useMemo, useState } from "react";
import { useDrag } from "@use-gesture/react";
import { animated, useSpring } from "react-spring";
import { useWindowSize } from "usehooks-ts";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

export interface ContentLayoutProps {
  toggleFilterList: (open?: boolean) => void;
  showMapButton: boolean;
}

const ContentLayout = ({
  children,
  showBanner,
}: {
  children: (props: ContentLayoutProps) => React.ReactNode;
  mobileInitialStart?: number;
  mobileInitialBottom?: number;
  showBanner: boolean;
}) => {
  const { publishEvent } = useEventTracker();
  const mobileBottomTouchPoint = 104;
  const bannerHeight = showBanner ? 130 : 52;

  const { height, width } = useWindowSize();
  const containerHeight = height - mobileBottomTouchPoint - bannerHeight;
  const [showMapButton, setShowMapButton] = useState(false);
  const [{ top }, api] = useSpring(() => ({
    top: containerHeight,
  }));

  const [dragBounds, setDragBounds] = useState({
    bottom: containerHeight,
    top: 0,
  });

  const snapPositions = useMemo(() => {
    return [0, containerHeight * 0.5, containerHeight];
  }, [containerHeight]);

  const nearestSnap = (current: number, positions: number[]): number => {
    return positions.reduce((prev, curr) =>
      Math.abs(curr - current) < Math.abs(prev - current) ? curr : prev,
    );
  };
  const nextSnap = (
    current: number,
    direction: number,
    positions: number[],
  ) => {
    const candidates =
      direction < 0
        ? positions.filter((p) => p <= current).sort((a, b) => b - a)
        : positions.filter((p) => p >= current).sort((a, b) => a - b);

    if (candidates.length === 0) {
      return nearestSnap(current, positions);
    }

    const nextClosestSnap = candidates[0];
    if (nextClosestSnap === current && candidates.length > 1) {
      return candidates[1];
    }
    return nextClosestSnap;
  };

  useEffect(() => {
    const newBottom = height - mobileBottomTouchPoint - bannerHeight;
    setDragBounds({ bottom: newBottom, top: 0 });

    const currentTop = top.get();
    if (currentTop > newBottom) {
      api.start({ top: newBottom });
    }
  }, [height, showBanner]);

  const inertiaThreshold = 0.4;

  const bind = useDrag(
    ({
      last,
      velocity: [_vx, vy],
      direction: [_dx, dy],
      offset: [_ox, oy],
    }) => {
      if (last) {
        let final: number;
        if (Math.abs(vy) > inertiaThreshold) {
          final = nextSnap(oy, dy < 0 ? -1 : 1, snapPositions);
        } else {
          final = nearestSnap(oy, snapPositions);
        }

        setShowMapButton(final === 0);
        api.start({
          config: { friction: 26, tension: 170 },
          immediate: false,
          top: final,
        });
        if (final === 0 && dy < 0) {
          publishEvent({
            action: Action.click,
            category: Category.finder,
            label: "list view",
          });
        }
      } else {
        api.start({ immediate: true, top: oy });
      }
    },
    {
      bounds: dragBounds,
      from: () => [0, top.get()],
    },
  );

  const toggleFilterList = (open?: boolean) => {
    if (!open) {
      setShowMapButton(false);
    }
    api.start({
      immediate: false,
      top: open ? 0 : containerHeight,
    });
  };

  return (
    <animated.div
      data-testid="content-layout-wrapper"
      className="bg-white touch-none md:h-full md:min-h-0 absolute z-10 md:relative bottom-0 top-full md:top-0 right-0 left-0"
      style={{
        ...(width < 768 && { top: top.to((val) => `${val}px`) }),
      }}
      {...bind()}
    >
      {children({ showMapButton, toggleFilterList })}
    </animated.div>
  );
};

export default ContentLayout;
