import React from "react";
import { useSelector } from "react-redux";

import { CopyBlock as CopyBlockType, FAQ } from "custom-types/Directus";
import { Location } from "custom-types/Location";
import { RetailType } from "custom-types/Store";
import { getShowBanner } from "redux/selectors/header";

import CopyBlock from "components/CopyBlock/CopyBlock";
import RetailCategoryNavigation from "components/dispensaries/RetailCategoryNavigation";
import FAQs from "components/FAQ";
import Footer from "components/Finder/Footer";

import { StoreLocatorResultsResponse } from "../../../api/requests/consumerApi/getStoreLocatorResults";
import { City } from "../../../api/requests/getCitiesNearby";
import PageNavigation from "../../PageNavigationV2";
import CitiesNearby from "../CitiesNearby/CitiesNearby";
import RecentlyViewedCarouselWithData from "../RecentlyViewedCarousel";
import StoreBreadcrumbs from "../StoreBreadcrumbs";
import StoreMenuContainer from "../StoreMenuContainer/StoreMenuContainer";
import ContentLayout from "./ContentLayout";
import { ContentLayoutProps } from "./ContentLayout/ContentLayout";

interface ContentSectionProps {
  retailType: RetailType;
  copyBlockData: CopyBlockType | null;
  faqs: FAQ | null;
  cities: City[];
  storeLocatorResults: StoreLocatorResultsResponse;
  page: number;
  totalPages: number;
  pageLocation: Location;
  showDeliveryValidation: boolean;
}

const ContentSection = ({
  storeLocatorResults,
  retailType,
  copyBlockData,
  faqs,
  cities,
  page,
  pageLocation,
  totalPages,
  showDeliveryValidation,
}: ContentSectionProps) => {
  const showBanner = useSelector(getShowBanner);
  const product = {
    "cbd-store": "hemp / CBD",
    clinic: "MMJ Doctors",
    dispensary: "weed",
    undefined: "weed",
  };
  return (
    <ContentLayout showBanner={showBanner}>
      {({ toggleFilterList, showMapButton }: ContentLayoutProps) => (
        <div className="flex flex-col h-full bg-white gap-md md:gap-0">
          <div
            className="pt-[10px] md:hidden flex justify-center items-center bg-white"
            data-testid="listing-wrapper-handle"
          >
            <span className="bg-light-grey rounded-full w-[64px] h-[6px]" />
          </div>
          <StoreMenuContainer
            showMapButton={showMapButton}
            storeLocatorResults={storeLocatorResults}
            toggleFilterList={toggleFilterList}
            faqs={faqs}
            retailType={retailType}
            pageLocation={pageLocation}
            showDeliveryValidation={showDeliveryValidation}
          >
            <div className="[&>*]:px-lg">
              <PageNavigation currentPage={page} totalPages={totalPages} />
              <RecentlyViewedCarouselWithData retailType={retailType} />
              <RetailCategoryNavigation retailType={retailType} />
              <CitiesNearby
                cities={cities}
                heading={`Find ${
                  product[retailType as keyof typeof product]
                } in a city near you`}
                retailType={retailType}
              />
              <hr className="border-light-grey my-xxl" />
              {copyBlockData?.copy_block?.length ? (
                <CopyBlock data={copyBlockData} />
              ) : (
                <></>
              )}
            </div>
            <div className="bg-green-20 my-xxl">
              <FAQs
                data={faqs?.faqs}
                initialShowPanel={true}
                className="py-xxl md:px-lg"
              />
            </div>
            <StoreBreadcrumbs pageLocation={pageLocation} />
            <Footer />
          </StoreMenuContainer>
        </div>
      )}
    </ContentLayout>
  );
};

export default ContentSection;
