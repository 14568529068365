import React from "react";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { useSearchParams } from "next/navigation";
import { parse, stringify } from "qs";

import getStoreLocatorResults, {
  STORE_LOCATOR_RESULTS_TAKE,
  StoreLocatorResultsResponse,
} from "api/requests/consumerApi/getStoreLocatorResults";
import { getCopyBlock } from "api/requests/directus/getCopyBlock";
import { getDirectusFAQs } from "api/requests/directus/getDirectusFAQs";
import getCitiesNearby, { City } from "api/requests/getCitiesNearby";
import { StoreLocatorProvider } from "context/StoreLocatorContext";
import { CountryCode } from "custom-types/CountryCode";
import { CopyBlock, FAQ } from "custom-types/Directus";
import { Location } from "custom-types/Location";
import { Query } from "custom-types/Query";
import { RetailType } from "custom-types/Store";
import { MapData } from "custom-types/StoreLocator";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import { getCountryCodeHeader } from "utils/requestContext";
import {
  getLocation,
  StoreLocatorLocation,
} from "utils/storeLocator/getLocation";
import getRetailTypeFromUrl from "utils/storeLocator/getRetailTypeFromUrl";
import getStoreLocatorRoute from "utils/storeLocator/getStoreLocatorRoute";
import replaceLegacyFinderFilters from "utils/storeLocator/replaceLegacyFinderFilters";

import ContentSection from "components/dispensaries/ContentSection";
import DispensariesHead from "components/dispensaries/DispensariesHead/DispensariesHead";
import MapSection from "components/dispensaries/MapSection";
import { LeaflyAppProps } from "pages/_app";

import { getMapSetup } from "../../utils/storeLocator/getMapSetup";

export interface StoreLocatorPageProps {
  faqs: FAQ | null;
  filter: Query;
  copyBlockData: CopyBlock | null;
  hideFooter: boolean;
  initMapData: MapData;
  retailType: RetailType;
  cities: City[];
  storeLocatorResults: StoreLocatorResultsResponse;
  pageLocation: StoreLocatorLocation;
  showDeliveryValidation: boolean;
  showStoreLocator: boolean;
}

const StoreLocationPage: React.FC<StoreLocatorPageProps> = ({
  copyBlockData,
  faqs,
  initMapData,
  retailType,
  storeLocatorResults,
  cities,
  pageLocation,
  showDeliveryValidation,
}) => {
  const page = Number(useSearchParams()?.get("page")) || 1;
  const totalPages = Math.ceil(
    storeLocatorResults.metadata.totalCount / STORE_LOCATOR_RESULTS_TAKE,
  );
  return (
    <>
      <DispensariesHead
        seo={storeLocatorResults.metadata.seo}
        seoOverride={faqs}
        stores={[
          ...storeLocatorResults.data.sponsoredStores,
          ...storeLocatorResults.data.organicStores,
        ]}
      />
      <>
        <div className="stores-page relative md:grid md:grid-cols-[var(--stores-desktop-listing-width),_1fr] h-full w-full">
          <StoreLocatorProvider>
            <ContentSection
              retailType={retailType}
              copyBlockData={copyBlockData}
              faqs={faqs}
              cities={cities}
              storeLocatorResults={storeLocatorResults}
              totalPages={totalPages}
              page={page}
              pageLocation={pageLocation.finderLocation as Location}
              showDeliveryValidation={showDeliveryValidation}
            />
            <MapSection
              retailType={retailType}
              initMapData={initMapData}
              spotlightStores={storeLocatorResults.data.spotlightStores}
              stores={[
                ...storeLocatorResults.data.sponsoredStores,
                ...storeLocatorResults.data.organicStores,
              ]}
            />
          </StoreLocatorProvider>
        </div>

        <style jsx global>{`
          .stores-page {
            --stores-desktop-listing-width: 380px;
            --stores-desktop-min-breakpoint: 1023px;
            --stores-collapsed-header-height: 48px;
            --stores-mobile-bottom-touchpoint: 104px;
            --stores-mobile-top-touchpoint: var(
              --stores-collapsed-header-height
            );
          }

          html,
          body,
          #modal-frame,
          #__next {
            height: 100%;
            max-height: 100%;
          }

          main {
            height: 100%;
            max-height: calc(
              100% - var(--header-height) - var(--app-banner-height)
            );
          }

          ::-webkit-scrollbar {
            width: 5px;
            height: 5px;
          }

          ::-webkit-scrollbar-track {
            background: gainsboro;
            border-radius: 5px;
          }

          ::-webkit-scrollbar-thumb {
            background: var(--color-green);
            border-radius: 5px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: var(--color-default);
          }
        `}</style>
      </>
    </>
  );
};
export const createStoreLocationPageProps = async (
  context: GetServerSidePropsContext,
): Promise<
  GetServerSidePropsResult<StoreLocatorPageProps & Partial<LeaflyAppProps>>
> => {
  const { query, resolvedUrl } = context;
  const [url] = resolvedUrl.split("#");
  const [path, qparams] = url.split("?");
  const { filter, page, sort, organization_id } = parse(qparams);

  const newQueryString = replaceLegacyFinderFilters(query);
  if (newQueryString) {
    return {
      redirect: {
        destination: `${path}${newQueryString ? `?${newQueryString}` : ""}`,
        statusCode: 301,
      },
    };
  }

  const countryCode = getCountryCodeHeader(context.req.headers) as CountryCode;
  const retailType = getRetailTypeFromUrl(resolvedUrl);

  const pageNumber = Number(page);

  const pageLocation = await getLocation(context);
  const initMapData = getMapSetup(query, pageLocation.finderLocation);

  const showDeliveryValidation =
    query["filter[fulfillment-methods]"] === "delivery";
  const [storeLocatorResults, cities] = await Promise.all([
    getStoreLocatorResults({
      filter: { ...(filter as Query) },
      location: pageLocation,
      mapData: initMapData,
      organization_id: organization_id as string | string[] | undefined,
      page: pageNumber,
      retailType,
      sort: sort as string | undefined,
    }),
    getCitiesNearby({
      countryCode,
      location: pageLocation.finderLocation as Location,
      retailType,
    }),
  ]);

  const slug =
    storeLocatorResults.metadata.seo.canonicalUrl ||
    getStoreLocatorRoute(retailType, countryCode);
  const [{ data: faqData }, copyBlockData] = await Promise.all([
    getDirectusFAQs("faq", slug, countryCode),
    getCopyBlock("copy_block", slug, countryCode),
  ]);

  const lastPage = Math.ceil(
    storeLocatorResults.metadata.totalCount / STORE_LOCATOR_RESULTS_TAKE,
  );
  if (pageNumber > 1 && pageNumber > lastPage) {
    const newQueryString = parse(qparams);
    newQueryString.page = (lastPage || 1).toString();

    return {
      redirect: {
        destination: `${path}?${stringify(newQueryString, {
          arrayFormat: "brackets",
          indices: false,
        })}`,
        statusCode: 302,
      },
    };
  }

  return {
    props: {
      cities,
      copyBlockData,
      faqs: faqData,
      filter: { ...(filter as Query) },
      hideFooter: true,
      initMapData,
      pageLocation,
      retailType,
      showDeliveryValidation,
      showStoreLocator: true,
      storeLocatorResults,
    },
  };
};

export const getServerSideProps =
  createGetServerSideProps<StoreLocatorPageProps>(
    async (context) => await createStoreLocationPageProps(context),
  );

export default StoreLocationPage;
