import React from "react";

import { CBD_STORE, CLINIC, DISPENSARY } from "constants/storeLocatorConfig";
import { RetailType } from "custom-types/Store";
import useDomainCountryCode from "hooks/useDomainCountryCode";

import LinkButton from "../LinkButton";

const RetailCategoryNavigation: React.FC<{
  retailType: RetailType;
}> = ({ retailType }) => {
  const countryCode = useDomainCountryCode();

  const categoryData = [
    {
      label: "Dispensaries",
      link: "/dispensaries",
      showCategory: retailType !== DISPENSARY,
      subcopy: `Find licensed medical marijuana doctors${
        countryCode === "CA"
          ? " or clinics nearby"
          : ", clinics, or CBD-only stores nearby."
      }`,
      type: DISPENSARY,
    },
    {
      label: "Doctors & clinics",
      link: "/medical-marijuana-doctors",
      showCategory: retailType !== CLINIC,
      subcopy: `Find legal dispensaries${countryCode === "CA" ? "" : " or CBD-only stores nearby"}.`,
      type: CLINIC,
    },
    {
      label: "CBD stores",
      link: "/cbd-stores",
      showCategory: retailType !== CBD_STORE && countryCode !== "CA",
      subcopy:
        "Find legal dispensaries or licensed medical marijuana doctors and clinics nearby.",
      type: CBD_STORE,
    },
  ];

  const { subcopy } =
    categoryData.find((data) => data.type === retailType) || categoryData[0];

  return (
    <div className="bg-leafly-white md:px-lg">
      <div
        className="container my-xxl py-lg"
        role="complementary"
        aria-label="Retail Category Links"
      >
        <h2 className="mb-sm">Looking for something else?</h2>
        <p className="text-sm">{subcopy}</p>

        <div className="py-md lg:py-lg flex flex-row">
          {categoryData
            .filter(({ showCategory }) => showCategory)
            .map(({ label, link }, index) => (
              <LinkButton
                key={index}
                header={label}
                link={link}
                trackingInfo={{ category: "Link", label }}
                className="flex-1 mr-sm shadow-low"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RetailCategoryNavigation;
