"use client";

import React from "react";

import { RetailType } from "custom-types/Store";
import { Store } from "custom-types/StoreLocator";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { getRetailTypeLabel } from "utils/dispensary/getRetailTypeLabel";

import StoreCard from "../StoreCard";

const StoreListings = ({
  sponsoredStores,
  stores,
  retailType,
}: {
  sponsoredStores: Store[];
  stores: Store[];
  retailType: RetailType;
}) => {
  const countryCode = useDomainCountryCode();

  if (!sponsoredStores?.length && !stores?.length) {
    return null;
  }

  return (
    <div className="bg-white flex flex-col gap-lg">
      {sponsoredStores?.length > 0 && (
        <div>
          <div
            className="flex flex-row justify-between"
            data-testid="sponsored-section"
          >
            <h2 className="text-xs uppercase my-sm text-default">
              {`Sponsored ${getRetailTypeLabel(retailType, countryCode, true)}`}
            </h2>
          </div>
          <ul className="flex flex-col bg-white">
            {sponsoredStores.map((sponsoredStore, i) => {
              return (
                <StoreCard
                  key={sponsoredStore.id}
                  isSponsored={true}
                  slot={i}
                  store={sponsoredStore}
                />
              );
            })}
          </ul>
        </div>
      )}
      {stores?.length > 0 && (
        <div>
          <div
            className="flex flex-row items-center justify-between"
            data-testid="all-stores-section"
          >
            <h2 className="text-xs uppercase text-default mb-sm">
              {`All ${getRetailTypeLabel(retailType, countryCode)} results`}
            </h2>
          </div>
          <ul className="flex flex-col bg-white">
            {stores.map((store, i) => {
              return (
                <StoreCard
                  key={store.id}
                  isSponsored={false}
                  slot={i}
                  store={store}
                  data-testid="store-card"
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StoreListings;
